import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import { Link, graphql } from "gatsby";
import { getData } from "../../helper/helper";
import Layout from "../../components/Layout";
import Services from "../../components/Services";
import Accordion from "../../components/Accordion";

const LaserPage = ({ pageContext: { locale }, ...props }) => {
  const { edges: posts } = props.data.laserPosts;
  const { node: data } = props.data.homePageData.edges[0];
  const { node: info } = props.data.LaserInfoData.edges[0];
  const ourservices = data.frontmatter.ourservices;
  const laser = getData(locale).laserPage;
  return (
    <Layout
      locale={locale}
      title={info.frontmatter.title}
      description={info.frontmatter.description}
    >
      <Helmet>
        <title>{info.frontmatter.title}</title>
        <meta name="description" content={`${info.frontmatter.description}`} />
      </Helmet>
      <section className="subPage">
      <div className="image">
          <Img
            fluid={info.frontmatter.image.childImageSharp.fluid}
            alt={info.frontmatter.title}
            title={info.frontmatter.title}
            className="post-image"
          />
          <div className="titles">
            <h1>{info.frontmatter.title}</h1>
            <p>{info.frontmatter.description}</p>
          </div>
        </div>
        <div className="container services">
          <div className="columns">
            {posts.map(({ node: post }) => (
              <Link
                className="column"
                key={post.fields.slug}
                to={`${post.fields.slug}`}
                title={post.frontmatter.title}
              >
                <div className="cover">
                  <Img
                    fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
                    alt={post.frontmatter.title}
                    title={post.frontmatter.title}
                    className="image"
                  />
                  <div className="content">
                    <h4>{post.frontmatter.title}</h4>
                    <p>{post.frontmatter.description}</p>
                  </div>
                  <span>
                    <p>{laser.button}</p>
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="pageInfo">
          <Accordion
            content={info.html}
            title={info.frontmatter.contentTitle}
          />
        </div>
        <div className="ourservices">
          <div className="container sub">
            <h5>{ourservices.heading}</h5>
            <Services
              gridItems={ourservices.ourservice}
              filter="laser-treatments"
              locale={locale}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LaserPage;

LaserPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
  posts: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  info: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export const pageQuery = graphql`
  query LaserPageQuery($locale: String) {
    laserPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          pageKey: { eq: "page_laserpost" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 200)
          id
          frontmatter {
            title
            pageKey
            description
            category
            locale
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 350, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }

    LaserInfoData: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "laser-info" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            locale
            title
            description
            contentTitle
            image {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    homePageData: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "index-page" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            templateKey
            locale
            ourservices {
              heading
              ourservice {
                image {
                  childImageSharp {
                    fluid(maxHeight: 500, quality: 75) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                header
                link
              }
            }
          }
        }
      }
    }
  }
`;
